@import '~/ui/assets/styles/colors.module.scss';

.header {
  display: flex;
  justify-content: space-between;
}

.subTitle {
  font-weight: 600;
  margin: 10px 0;
}

.date {
  color: $color-red-2;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  margin-right: 15px;
}

.formWrapper {
  width: 100%;
  overflow: scroll;
  max-height: calc(100vh - 360px);
}

.subTitle {
  font-weight: 600;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.image {
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
}

.deletedUser {
  opacity: 0.9;
}

.assignedClientsTitle {
  margin: 8px 0;
}

.absoluteIcon {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 20px;
  width: 0;
  height: 0;
}
