@import '~/ui/assets/styles/colors.module.scss';

.accordion {
  box-shadow: none !important;
  margin-top: 8px !important;
  width: 100%;
  min-height: auto;

  &::before,
  &::after {
    display: none !important;
  }

  .accordionSummaryItemLeft,
  .accordionSummaryItemRight,
  .accordionSummaryItemCenter {
    font-size: 16px;
    font-weight: 600;
    align-self: center;
  }

  .accordionSummaryItemRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .accordionDetailsContainer {
    margin-top: 16px;
    padding: 8px 60px 0 0px;
  }

  .accordionSummaryItemName {
    display: flex;
    align-items: center;
  }

  .image {
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    width: 40px;
    height: 40px;
  }

  :global {
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 60px;
    }

    .MuiAccordionDetails-root {
      display: block;
    }

    .MuiAccordionSummary-root {
      background-color: $color-grey-22;
      border-radius: 16px;
      border: none;
      margin: 0;
      width: 100%;
      padding: 6px 15px;

      .MuiAccordionSummary-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;
      }
    }
  }

  .accordionSummaryItemCenter {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordionSummaryItemRight {
    text-align: right;
  }
}

.selectedClient {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deleteIcon {
  display: flex;
  justify-content: flex-end;
}

.memoContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.assignedClientsTitle {
  margin: 12px 0 !important;
}

.clientDetailItem {
  margin: 12px 0;
}

.photoContainer {
  position: relative;
  display: flex;
  align-items: flex-end;

  .absoluteIcon {
    position: absolute;
    bottom: -8px;
    right: 8px;
  }
}